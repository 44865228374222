




import Vue from 'vue';
import { mapState } from 'vuex';
//@ts-ignore
import { ModelViewerElement } from '@google/model-viewer/dist/model-viewer';
import store from '@/store/index';
import AssetSubmissionIssueHotspot from '@/store/interfaces/AssetSubmissionIssueHotspot';
import RabbitButton from '@/components/buttons/RabbitButton.vue';
import { SphericalPosition } from '@google/model-viewer/lib/features/controls';
import { USER_ROLE_TYPE } from '@/store/interfaces/types/UserRoleType';

export default Vue.extend({
  components: {
    ModelViewerElement,
    RabbitButton,
  } as any,
  computed: {
    environmentImage(): string {
      if (this.hdr == 'neutral') {
        return 'neutral'; // built into model-viewer
      } else if (this.hdr == 'default') {
        return ''; // built in feature of model-viewer
      } else {
        // Adding www_ to the names to prevent caching bug where CORS blocks
        // cached hdr files from different sub-domains (www. vs view.)
        // Currently need to maintain a copy of the hdrs with the www_ prefix
        return 'https://cdn.3xr.com/images/hdr/www_' + this.hdr + '_1k.hdr';
      }
    },
    exposure(): string {
      if (this.exposureOverride) {
        return String(this.exposureOverride);
      }
      return String(this.assetSubmission.asset.exposure ?? 1);
    },
    isClient: () => store.getters.user.isClient,
    hotspotsOpen(): Array<any> {
      let hotspots = [];
      for (let i = 0; i < this.assetSubmission.openIssues.length; i++) {
        for (let h = 0; h < this.assetSubmission.openIssues[i].hotspots.length; h++) {
          let hotspot = this.assetSubmission.openIssues[i].hotspots[h];
          hotspot.issueId = this.assetSubmission.openIssues[i].id;
          if (!this.isClient || this.assetSubmission.openIssues[i].authorRoleId == USER_ROLE_TYPE.CLIENT) {
            // hide 3xr QA hotspots from client
            hotspots.push(hotspot);
          }
        }
      }
      return hotspots;
    },
    hotspotsResolved(): Array<any> {
      let hotspots = [];
      for (let i = 0; i < this.assetSubmission.resolvedIssues.length; i++) {
        for (let h = 0; h < this.assetSubmission.resolvedIssues[i].hotspots.length; h++) {
          let hotspot = this.assetSubmission.resolvedIssues[i].hotspots[h];
          hotspot.issueId = this.assetSubmission.resolvedIssues[i].id;
          hotspots.push(hotspot);
        }
      }
      return hotspots;
    },
    iosSrc(): string {
      return (
        'https://x.3xr.com/x/assets/' +
        this.uid +
        '/submissions/' +
        this.assetSubmission.submissionNumber +
        '/' +
        this.name +
        '.usdz'
      );
    },
    largeThumbnail(): string {
      return (
        'https://x.3xr.com/x/assets/' +
        this.uid +
        '/submissions/' +
        this.assetSubmission.submissionNumber +
        '/' +
        this.name +
        '-1k.png'
      );
    },
    shadowIntensity(): string {
      if (this.showShadow) {
        return String(this.assetSubmission.asset.shadowIntensity ?? 1);
      } else {
        return String(0);
      }
    },
    src(): string {
      return (
        'https://x.3xr.com/x/assets/' +
        this.uid +
        '/submissions/' +
        this.assetSubmission.submissionNumber +
        '/' +
        this.name +
        '.glb'
      );
    },
    ...mapState({
      // this is in vuex so it can be passed across components (connected to QaSubmissionIssueForm)
      assetSubmissionIssueHotspots: (state: any) => state.assetSubmissionIssueHotspots,
    }),
  },
  data: () => ({
    filename: '',
    newHotspotCounter: 1,
    modelRotation: 0,
  }),
  methods: {
    addHotspot: function (event: MouseEvent): void {
      if (this.assetSubmissionIssueHotspots.addingHotspot) {
        const viewer = this.$el.getElementsByTagName('model-viewer')[0] as ModelViewerElement;
        const x = event.clientX;
        const y = event.clientY;
        // as of 1.9.1 absolute position is used instead of relative
        const positionAndNormal = viewer.positionAndNormalFromPoint(x, y);
        if (positionAndNormal) {
          const { position, normal } = positionAndNormal;
          let hotspot = new AssetSubmissionIssueHotspot({
            assetSubmissionIssueId: 0, // not created yet
            id: this.newHotspotCounter++,
            normal: normal.toString(),
            position: position.toString(),
          });
          store.dispatch.assetSubmissionIssueHotspots.addHotspot(hotspot);
          store.dispatch.assetSubmissionIssueHotspots.stopAdding();
          const overlay = this.$el as HTMLInputElement;
          if (overlay) {
            overlay.blur();
          }
        }
      }
    },
    cancelAddingHotspot(): void {
      store.dispatch.assetSubmissionIssueHotspots.stopAdding();
      const overlay = this.$el as HTMLInputElement;
      if (overlay) {
        overlay.blur();
      }
    },
    async saveImage(): Promise<void> {
      let posterUrl = '';
      const viewer = this.$el.getElementsByTagName('model-viewer')[0] as ModelViewerElement;
      const orbit = viewer.getCameraOrbit();
      this.setFilenameForOrbit(orbit);
      viewer.fieldOfView = 'auto';
      viewer.jumpCameraToGoal();
      await new Promise<void>((resolve) => requestAnimationFrame(() => resolve()));
      URL.revokeObjectURL(posterUrl);
      const blob = await viewer.toBlob({
        mimeType: 'image/png',
        idealAspect: true,
      });
      posterUrl = URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = posterUrl;
      a.download = this.filename;
      a.click();
    },
    imageRenderedFailureCallback(): void {
      store.dispatch.assetSubmission.refresh();
      // TODO: The rabbit message is being marked failed when it is working
      this.masterRenderCompleteCallback(this.filename);
    },
    imageRenderedSuccessCallback(): void {
      store.dispatch.assetSubmission.refresh();
      // Switch to 2D view and select the new render
      this.masterRenderCompleteCallback(this.filename);
    },
    renderMasterImageData(): object {
      const viewer = document.getElementById('viewer') as ModelViewerElement;
      if (!viewer) {
        return {};
      } else {
        const orbit = viewer.getCameraOrbit();
        this.setFilenameForOrbit(orbit);
        // Invert theta so x rotation is clockwise from front
        let theta = 0 - orbit.theta;
        if (theta < 0) {
          // Convert negative angles to positive
          theta = theta + 2 * Math.PI;
        }
        let phi = orbit.phi - Math.PI / 2;
        if (phi < 0) {
          // Convert negative angles to positive
          phi = phi + 2 * Math.PI;
        }
        return {
          assetUid: this.assetSubmission.asset.uid,
          filename: this.filename,
          name: this.assetSubmission.asset.name,
          phi: phi,
          radius: orbit.radius,
          submissionId: this.assetSubmission.id,
          submissionNumber: this.assetSubmission.submissionNumber,
          theta: theta,
        };
      }
    },
    selectHotspot(id: number): void {
      if (this.assetSubmissionIssueHotspots.assetSubmissionIssueId == id) {
        // If it is already selected, turn it off
        store.dispatch.assetSubmissionIssueHotspots.selectIssue(0);
      } else {
        store.dispatch.assetSubmissionIssueHotspots.selectIssue(id);
      }
    },
    setFilenameForOrbit(orbit: SphericalPosition): void {
      let theta = 0 - orbit.theta;
      if (theta < 0) {
        // Convert negative angles to positive
        theta = theta + 2 * Math.PI;
      }
      let phi = orbit.phi - Math.PI / 2;
      if (phi < 0) {
        // Convert negative angles to positive
        phi = phi + 2 * Math.PI;
      }
      const orbitX = Math.floor((theta * 180) / Math.PI);
      const orbitY = Math.floor((phi * 180) / Math.PI);
      this.filename = this.assetSubmission.asset.name + '-' + orbitX + '_' + orbitY + '.png';
    },
    rotateHDR(rotationAmount: number): void {
      const modelViewer: ModelViewerElement = document.querySelector('#qaViewer');
      if (modelViewer) {
        const orbit = modelViewer.getCameraOrbit();
        const rotationAmountRadians = rotationAmount * (Math.PI / 180);
        orbit.theta += rotationAmountRadians;
        this.modelRotation += rotationAmountRadians;
        modelViewer.cameraOrbit = orbit.toString();
        modelViewer.resetTurntableRotation(this.modelRotation);
        modelViewer.jumpCameraToGoal();
      }
    },
  },
  props: {
    assetSubmission: Object,
    exposureOverride: Number,
    hdr: String,
    hdrRotation: Number,
    name: String,
    masterRenderCompleteCallback: Function,
    showImageButtons: Boolean,
    showShadow: Boolean,
    uid: String,
  },
  watch: {
    hdrRotation: function (oldValue, newValue) {
      this.rotateHDR(oldValue - newValue);
    },
  },
});
