




import Vue from 'vue';
import { vuetifyComponents } from '@/plugins/vuetify';
import ImageViewer from '@/components/image/ImageViewer.vue';
import store from '@/store/index';

export default Vue.extend({
  name: 'asset-submission-textures',
  components: {
    ...vuetifyComponents,
    ImageViewer,
  },
  computed: {
    deleteEnabled(): Boolean {
      return this.assetSubmission.textures.length > 0 && (this.isAdmin || this.role === 'artist');
    },
    isAdmin: () => store.getters.user.isAdmin,
    role: () => store.getters.user.role,
    textureSrc(): String {
      if (this.assetSubmission.textures.length > 0) {
        return this.assetSubmission.textures[this.selectedIndex].src;
      } else {
        return 'https://cdn.3xr.com/images/image_not_available.svg';
      }
    },
  },
  data: function () {
    return {
      selectedIndex: 0,
      sliderStatus: null,
    };
  },
  props: {
    assetSubmission: { type: Object, required: true },
    loading: Boolean,
    product: { type: Object, required: true },
  },
});
